import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  IAIChatSession,
  IAIChatSessionMessage,
  IChatRequest,
  AiChatSessionType,
  AIChatResponse,
} from "../../../../types";
import { ApiResponse, fetchApi, postApi } from "../../../../utils/api/api";
import ApiRoutes from "../../../../routes/backend_routes";
import {
  fetchSessionsFailure,
  fetchSessionsRequest,
  fetchSessionsSuccess,
  sendMessageFailure,
  sendMessageRequest,
  sendMessageSuccess,
} from "../../../features/manager/ai/aiChatSlice";
import { ConstructionOutlined } from "@mui/icons-material";

// Define interfaces for different response types
interface BaseAIResponse {
  session_id: number;
  message: IAIChatSessionMessage;
}

interface EventCreationResponse extends BaseAIResponse {
  event_data?: {
    name: string;
    description: string;
    // ... other event fields
  };
}

interface GeneralChatResponse extends BaseAIResponse {
  suggestions?: string[];
  links?: string[];
}

// Type guard to check response types
const isEventCreationResponse = (
  response: any,
  sessionType: AiChatSessionType
): response is EventCreationResponse => {
  return sessionType === "event_generation" && "event_data" in response;
};

function* sendMessageSaga(
  action: PayloadAction<IChatRequest>
): Generator<any, void, any> {
  try {
    const response: ApiResponse<AIChatResponse> = yield call(
      postApi,
      ApiRoutes.MANAGER_AI_CHAT,
      {
        session_id: action.payload.session_id,
        message: action.payload.message,
        organization_id: action.payload.organization_id,
        session_type: action.payload.session_type,
      },
      true,
      true
    );

    if (response.status === "success") {
      const { session_type } = action.payload;

      // Process response based on session type
      yield put(sendMessageSuccess(response.data));
      yield put(fetchSessionsRequest());
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || "Failed to send message";
    yield put(sendMessageFailure(errorMessage));
    toast.error(errorMessage);
  }
}

function* fetchSessionsSaga() {
  try {
    const response: ApiResponse<{ sessions: IAIChatSession[] }> = yield call(
      fetchApi,
      ApiRoutes.MANAGER_AI_SESSIONS,
      true,
      true
    );
    yield put(fetchSessionsSuccess(response.data.sessions));
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || "Failed to fetch sessions";
    yield put(fetchSessionsFailure(errorMessage));
    toast.error(errorMessage);
  }
}

export default function* watchAIChatSaga() {
  yield takeLatest(sendMessageRequest.type, sendMessageSaga);
  yield takeLatest(fetchSessionsRequest.type, fetchSessionsSaga);
}
