import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { IEvent, ISelectedAddon } from "../../../types";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Box,
  DialogTitle,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import { useMediaQuery, useTheme } from "@mui/material";
import usePalette from "../../../theme/use_palette";
import Title from "../../../components/text/title";
import StandardToastContainer from "../../../components/wrappers/toast_container";
import { ticketReleaseHasClosed } from "../../../utils/event_open_close";
import { useTranslation } from "react-i18next";
import usePromoCodes from "../../../hooks/event/use_event_promo_code_hook";
import { useEventEffects } from "../../../hooks/event/event_detail_hook";
import { motion } from "framer-motion"; // Import Framer Motion
import LoadingOverlay from "../../../components/Loading";
import { PromoCodeForm } from "./promo_code_form";
import { ScrollConfig } from "../../../components/constant/scroll_config";
import ShowEventsTicketReleases from "./show_events_ticket_releases";
import Footer from "../../../components/wrappers/footer";
import FourOFour404 from "../../errors/404";
import BottomCart from "../../../components/events/ticket_release/cart/bottom_cart";
import CartSummaryModal from "../../../components/events/ticket_release/cart/cart_summary_modal";
import ConfirmSwitchReleaseModal from "../../../components/events/ticket_release/cart/confirm_switch_ticket_release_modal";
import {
  postTicketOrderRequest,
  resetCurrentTicketReleaseId,
} from "../../../redux/features/ticketOrderSlice";
import InformationModal from "../../../components/modal/information";
import MakeTicketOrderWorkflow from "../../../components/events/ticket_release/ticket_request/make_ticket_request_work_flow";
import { requiresUpfrontPayment } from "../../../utils/manage_event/can_edit_payment_deadline";
import { createGuestTicketOrderRequest } from "../../../redux/features/guestCustomerSlice";
import TicketReleasesSidebar from "../../../components/events/ticket_release/ticket_releases_sidebar";
import {
  getCustomerEventRequest,
  resetCustomerEvent,
} from "../../../redux/features/customerViewEvent";
import NavigationBar from "../../../components/navigation";

interface EventLandingPageProps {
  subdomain?: string;
}

const EventLandingPage: React.FC<EventLandingPageProps> = ({ subdomain }) => {
  const { refID } = useParams<{ refID: string }>();
  const [loadingEditor, setLoadingEditor] = useState(true);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [makeTicketOrderModalOpen, setMakeTicketOrderModalOpen] =
    useState(false);
  const [tickets, setTickets] = useState<
    {
      ticket_type_id: number;
      addons: ISelectedAddon[];
    }[]
  >([]);

  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const secretToken = queryParams.get("secret_token");
  const palette = usePalette();

  const { submitPromoCode } = usePromoCodes(
    refID!,
    subdomain || "",
    secretToken || ""
  );

  const { loading, error, event, errorStatusCode } = useSelector(
    (state: RootState) => state.customerViewEvent
  ) as {
    loading: boolean;
    error: string | null;
    event: IEvent | null;
    errorStatusCode: number | null;
  };
  const { timestamp } = useSelector((state: RootState) => state.timestamp);
  const { postSuccess } = useSelector((state: RootState) => state.ticketOrder);
  const guestCustomer = useSelector(
    (state: RootState) => state.auth.guestCustomer
  );
  const { currentTicketReleaseId } = useSelector(
    (state: RootState) => state.ticketOrder
  );

  const currentTicketRelease = event?.ticket_releases?.find(
    (tr) => tr.id === currentTicketReleaseId
  );
  const requireDirectPayment =
    requiresUpfrontPayment(
      currentTicketRelease?.ticket_release_method_detail.ticket_release_method!
    ) || false;

  useEventEffects(
    postSuccess,
    errorStatusCode,
    refID!,
    secretToken,
    subdomain,
    error
  );

  useEffect(() => {
    const buyTicketsButton = document.querySelector("#buy-tickets");
    const buyTicketsButton2 = document.querySelector("#buy-tickets-2");

    const handleClick = () => {
      setIsTicketModalOpen(true);
    };

    if (buyTicketsButton) {
      buyTicketsButton.addEventListener("click", handleClick);
    }

    if (buyTicketsButton2) {
      buyTicketsButton2.addEventListener("click", handleClick);
    }

    return () => {
      if (buyTicketsButton) {
        buyTicketsButton.removeEventListener("click", handleClick);
      }

      if (buyTicketsButton2) {
        buyTicketsButton2.removeEventListener("click", handleClick);
      }
    };
  }, [event]);

  const decodeHtmlEntities = (text: string) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
  };

  // Add this at the top level of the component
  useEffect(() => {
    if (refID) {
      dispatch(
        getCustomerEventRequest({
          refID,
          secretToken: secretToken || "",
          subdomain: subdomain || "",
        })
      );
      dispatch(resetCurrentTicketReleaseId());
    }
  }, [refID, secretToken, subdomain]); // Dependencies that should trigger a refetch

  useEffect(() => {
    // Cleanup function runs when component unmounts
    return () => {
      dispatch(resetCustomerEvent()); // You'll need to create this action
    };
  }, []);

  useEffect(() => {
    const setJsCode = () => {
      const script = document.createElement("script");
      let jsCode = event?.landing_page?.js!;
      jsCode = decodeHtmlEntities(jsCode);
      script.innerHTML = jsCode;
      document.body.appendChild(script);
    };

    if (event?.landing_page?.html && setLoadingEditor) {
      setJsCode();
      setLoadingEditor(false);
    }
  }, [event]);

  const handleOpenMakeOrderModal = (
    ticketsData: {
      ticket_type_id: number;
      addons: ISelectedAddon[];
    }[]
  ) => {
    setTickets(ticketsData);
    setMakeTicketOrderModalOpen(true);
  };

  const handleCloseMakeOrderModal = () => {
    setMakeTicketOrderModalOpen(false);
    dispatch(resetCurrentTicketReleaseId());
  };

  const getPromoCodes = () => {
    let existingPromoCodes: string[] = [];
    if (existingPromoCodes) {
      existingPromoCodes = JSON.parse(
        localStorage.getItem("promo_codes") || "[]"
      );
    } else {
      existingPromoCodes = [];
    }
    return existingPromoCodes ?? [];
  };

  const onSubmit = () => {
    dispatch(
      postTicketOrderRequest({
        promoCodes: getPromoCodes(),
        tickeOrderReq: {
          ticket_release_id: currentTicketRelease!.id!,
          tickets,
        },
        addons: [], // Not needed since addons are included per ticket
        eventId: currentTicketRelease!.event_id,
      })
    );
  };

  const onGuestSubmit = () => {
    dispatch(
      createGuestTicketOrderRequest({
        promoCodes: getPromoCodes(),
        ticketsOrderReq: {
          ticket_release_id: currentTicketRelease!.id!,
          tickets,
        },
        eventId: currentTicketRelease!.event_id,
        guestCustomer: guestCustomer!,
      })
    );
  };

  useEffect(() => {
    const parallaxImage = document.getElementById("hero-banner");

    const parallax = () => {
      if (parallaxImage) {
        const scrollPosition = window.scrollY;
        const offset = scrollPosition * 0.5;
        parallaxImage.style.transform = `scale(1.05) translateY(${offset}px)`;
      }
    };

    const handleScroll = () => {
      requestAnimationFrame(parallax);
    };

    window.addEventListener("scroll", handleScroll);

    // Initial call to set the position
    parallax();

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (!event) {
    return <FourOFour404 />;
  }

  const ticketReleases = event!.ticket_releases!.filter(
    (ticketRelease) => !ticketReleaseHasClosed(ticketRelease, timestamp)
  );

  const modalVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { type: "spring", stiffness: 300, damping: 30 },
    },
  };

  // Add this before the EventLandingPage component:

  return (
    <div>
      <NavigationBar
        options={{
          isEventPage: true,
        }}
      />
      <StandardToastContainer />
      <Modal
        open={isTicketModalOpen}
        onClose={() => setIsTicketModalOpen(false)}
      >
        <motion.div
          variants={modalVariants}
          initial="hidden"
          animate={isTicketModalOpen ? "visible" : "hidden"}
        >
          <ModalDialog
            variant="outlined"
            sx={{
              width: isScreenSmall ? "100%" : "65%",
              backgroundColor: palette.offWhite,
            }}
          >
            <Box
              sx={{ maxHeight: "800px", ...ScrollConfig, overflow: "scroll" }}
              pb={4}
            >
              <DialogTitle>
                <Title
                  color={palette.primaryMain}
                  style={{
                    margin: "0 auto",
                  }}
                >
                  Tickets
                </Title>
              </DialogTitle>
              <Box mt={2}>
                <ShowEventsTicketReleases
                  ticketReleases={ticketReleases}
                  event={event}
                />
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box>
                <PromoCodeForm onSubmit={submitPromoCode} />
              </Box>
              <ModalClose />
            </Box>
          </ModalDialog>
        </motion.div>
      </Modal>

      {/* Ticket Order Workflow Modal */}
      <InformationModal
        isOpen={makeTicketOrderModalOpen}
        onClose={handleCloseMakeOrderModal}
        title={t(
          "event.ticket_release.request_process.complete_ticket_request"
        )}
        width={isScreenSmall ? "100%" : "60%"}
        preventCloseReasons={["backdropClick"]}
      >
        <Box>
          <MakeTicketOrderWorkflow
            ticketRelease={currentTicketRelease!}
            onSubmitTicketOrder={onSubmit}
            onSubmitGuestTicketOrder={onGuestSubmit}
            onClose={handleCloseMakeOrderModal}
            payDirect={requireDirectPayment}
          />
        </Box>
      </InformationModal>
      {currentTicketRelease && (
        <>
          <BottomCart ticketRelease={currentTicketRelease} />
          <CartSummaryModal
            ticketRelease={currentTicketRelease}
            onProceed={handleOpenMakeOrderModal}
          />
        </>
      )}
      <ConfirmSwitchReleaseModal />

      <style
        dangerouslySetInnerHTML={{ __html: event?.landing_page!.css! }}
      ></style>
      <div
        dangerouslySetInnerHTML={{ __html: event?.landing_page!.html! }}
      ></div>

      <Footer />
    </div>
  );
};

export default EventLandingPage;
