import React, { useEffect, useRef } from "react";
import { ISelectedAddon, ITicketBasket, ITicketRelease } from "../../../types";
import { Box, Divider, Stack, Typography } from "@mui/joy";
import usePalette from "../../../theme/use_palette";
import TicketReleaseCountdown from "./tr_countdown";
import TicketType from "../ticket_types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  ShoppingCartItem,
  updateCartItemAddons,
} from "../../../redux/features/ticketOrderSlice";
import WhaIsTicketRequestModal from "./what_is_ticket_request";
import StyledButton from "../../buttons/styled_button";
import { useTranslation } from "react-i18next";
import StyledText from "../../text/styled_text";
import TicketReleaseAddons from "./addons";
import { useMediaQuery, useTheme } from "@mui/material";
import { resetLoginSuccess } from "../../../redux/features/authSlice";

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const TicketReleaseHasOpened: React.FC<{
  ticketRelease: ITicketRelease;
}> = ({ ticketRelease }) => {
  const { items: ticketOrderItems, loading: makingRequest } = useSelector(
    (state: RootState) => state.ticketOrder
  ) as {
    items: ShoppingCartItem[];
    loading: boolean;
  };

  const [basket, setBasketItems] = React.useState<ITicketBasket[]>([]);
  const [whatIsRequestOpen, setWhatIsRequestOpen] = React.useState(false);
  const [hasScrolled, setHasScrolled] = React.useState(false);

  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const palette = usePalette();

  const addonsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const basketItems: ITicketBasket[] = [];

    setBasketItems((prevBasket) => {
      const newBasket = [...prevBasket];

      ticketOrderItems.forEach((item, ticketIndex) => {
        const ticketType = ticketRelease.ticket_types?.find(
          (tt) => tt.id === item.ticket.id
        );

        if (ticketType) {
          const quantity = ticketOrderItems.filter(
            (item) => item.ticket.id === ticketType.id
          ).length;

          // For each ticket, ensure it is treated individually
          for (let i = 0; i < quantity; i++) {
            const basketItem: ITicketBasket = {
              id: ticketType.id,
              name: ticketType.name,
              quantity: 1,
              price: ticketType.price,
              type: "ticket", // Ensure the type is explicitly set to the string "ticket"
              addons: newBasket[ticketIndex]?.addons || [], // Preserve existing addons or initialize with empty
            };

            basketItems.push(basketItem);
          }
        }
      });

      return basketItems;
    });
  }, [ticketOrderItems, ticketRelease.ticket_types]);

  const cartItems = useSelector((state: RootState) => state.ticketOrder.items);
  const cartItemsForRelease = cartItems.filter(
    (item) => item.ticket.ticket_release_id === ticketRelease.id
  );

  // Handle addon change for a specific ticket in the basket
  const handleAddonChange = (
    cartItemId: string,
    updatedAddons: ISelectedAddon[]
  ) => {
    dispatch(updateCartItemAddons({ cartItemId, addons: updatedAddons }));
  };

  useEffect(() => {
    dispatch(resetLoginSuccess());
  }, [dispatch]);

  useEffect(() => {
    if (cartItemsForRelease.length === 1 && addonsRef.current && !hasScrolled) {
      addonsRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      setHasScrolled(true);
    }
  }, [cartItemsForRelease]);

  return (
    <>
      {/* {makingRequest && <LoadingOverlay />} */}
      <Stack spacing={2} sx={{ p: 0 }} mt={2}>
        {ticketRelease.ticket_types!.length > 0 ? (
          ticketRelease.ticket_types!.map((ticketType, i) => {
            const key = `${ticketType.id}-${i}`;
            return (
              <TicketType
                ticketType={ticketType}
                maxTicketsPerUser={
                  ticketRelease.ticket_release_method_detail
                    ?.max_tickets_per_user
                }
                key={key}
              />
            );
          })
        ) : (
          <Typography level="body-sm" fontFamily={"Libre Franklin"}>
            {t("event.ticket_release.no_tickets")}
          </Typography>
        )}
      </Stack>
      {ticketRelease.add_ons && ticketRelease.add_ons.length > 0 && (
        <div ref={addonsRef}>
          <TicketReleaseAddons
            ticketRelease={ticketRelease}
            cartItems={cartItemsForRelease}
            handleChange={handleAddonChange}
          />
        </div>
      )}
      {basket! && (
        <Box
          sx={{
            maxWidth: "100%",
          }}
        >
          <Divider
            sx={{
              mb: 1,
              width: "100%",
            }}
          />
          {/* Total */}

          <WhaIsTicketRequestModal
            isOpen={whatIsRequestOpen}
            onClose={() => {
              setWhatIsRequestOpen(false);
            }}
          />
        </Box>
      )}
      <div
        style={{
          marginTop: ".5rem",
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <StyledText
          level="body-sm"
          fontWeight={600}
          fontSize={15}
          color={palette.charcoal}
        >
          {t("event.ticket_release.tickets_available_for")}:
        </StyledText>
        <TicketReleaseCountdown
          ticketRelease={ticketRelease}
          fw={500}
          fs={16}
          useOpen={false}
        />
      </div>
    </>
  );
};

export default TicketReleaseHasOpened;
