import { Typography, TypographyTypeMap } from "@mui/joy";
import React from "react";
import usePalette from "../../theme/use_palette";

interface StyledTextProps {
  children: React.ReactNode;
  level: string;
  color?: string;
  fontWeight?: number;
  fontSize?: number | string;
  style?: React.CSSProperties;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  onClick?: () => void;
  forceTextPointer?: boolean;
  sx?: any;
  className?: string; // Add className as an optional prop
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  ref?: React.Ref<HTMLDivElement>;
}

const StyledText: React.FC<StyledTextProps> = ({
  children,
  level,
  color,
  style,
  fontSize = 18,
  startDecorator,
  endDecorator,
  fontWeight,
  forceTextPointer = false,
  onMouseEnter,
  onMouseLeave,
  onClick,
  sx = {},
  className, // Destructure className
  ref,
  ...rest
}) => {
  const palette = usePalette();
  return (
    <Typography
      // @ts-ignore
      level={level}
      fontFamily={"Libre Franklin"}
      fontWeight={fontWeight}
      onClick={onClick}
      fontSize={fontSize}
      startDecorator={startDecorator}
      endDecorator={endDecorator}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        ...sx,
        cursor: onClick || forceTextPointer ? "pointer" : "inherit",
        letterSpacing: "0.6px",
        lineHeight: "1.5",
      }}
      style={{
        ...style,
        color: color || palette.charcoal,
      }}
      tabIndex={-1} // Prevent the text from being focusable
      className={className} // Pass className to Typography
      ref={ref} // Pass ref to Typography
      {...rest} // Spread rest props
    >
      {children}
    </Typography>
  );
};

export default StyledText;
