import { Button, IconButton, Typography } from "@mui/joy";
import usePalette from "../../../theme/use_palette";
import { useEffect, useState } from "react";
import { ITicketRelease } from "../../../types";
import { differenceInSeconds, intervalToDuration } from "date-fns";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import StyledText from "../../text/styled_text";

const TicketReleaseCountdown: React.FC<{
  ticketRelease: ITicketRelease;
  fw: number;
  fs: number;
  useOpen?: boolean;
}> = ({ ticketRelease, fw, fs, useOpen }) => {
  const palette = usePalette();
  const openDate = new Date(ticketRelease.open);
  const closeDate = new Date(ticketRelease.close);

  const targetDate: Date = useOpen ? openDate : closeDate;
  const now = new Date();

  const calculateDuration = () => {
    return intervalToDuration({
      start: now,
      end: targetDate.getTime(),
    });
  };

  const [duration, setDuration] = useState(calculateDuration());
  const [isOpen, setIsOpen] = useState(useOpen && now >= openDate);

  useEffect(() => {
    // Check if current date is past the target date
    if (new Date() >= targetDate) {
      clearInterval(1);
      return;
    }

    // Set up a timer to tick down every second
    const intervalId = setInterval(() => {
      setDuration(calculateDuration());
      setIsOpen(useOpen && new Date() >= openDate);
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [targetDate, useOpen, openDate]);

  const formatTime = (time: number | undefined) => {
    // Default to 0 if time is undefined
    return String(time ?? 0);
  };

  const { t } = useTranslation();

  if (isOpen) {
    return (
      <>
        <IconButton
          onClick={() => setDuration(calculateDuration())}
          sx={{ mt: 1, mb: 4 }}
        >
          <StyledText
            color={palette.charcoal}
            level="body-md"
            onClick={() => {
              window.location.reload();
            }}
            fontSize={fs}
            fontWeight={fw}
            startDecorator={<RefreshIcon />}
          >
            Refresh
          </StyledText>
        </IconButton>
      </>
    );
  }

  return (
    <>
      <StyledText
        color={palette.charcoal}
        level="body-md"
        fontSize={fs}
        fontWeight={fw}
        sx={{
          mb: 3,
        }}
      >
        {duration.months! > 0 && (
          <span>
            {formatTime(duration.months)} {t("event.ticket_release.month")}
          </span>
        )}{" "}
        {duration.days! > 0 && (
          <span>
            {formatTime(duration.days)} {t("event.ticket_release.day")}
          </span>
        )}{" "}
        {duration.hours! > 0 && (
          <span>
            {formatTime(duration.hours)} {t("event.ticket_release.hour")}
          </span>
        )}{" "}
        {duration.minutes! > 0 && (
          <span>
            {formatTime(duration.minutes)} {t("event.ticket_release.minute")}
          </span>
        )}{" "}
        {duration.seconds! > 0 && (
          <span>
            {formatTime(duration.seconds)} {t("event.ticket_release.second")}
          </span>
        )}
      </StyledText>
    </>
  );
};

export default TicketReleaseCountdown;