// ManagerLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import DrawerBoxWrapper from "../../components/wrappers/manager_wrapper";
import MUITesseraWrapper from "../../components/wrappers/page_wrapper_mui";

const ManagerLayout: React.FC = () => {
  return (
    <MUITesseraWrapper navOptions={{ isDashboard: true }}>
      <DrawerBoxWrapper showManagerDashboard={true}>
        <Outlet /> {/* Renders child routes */}
      </DrawerBoxWrapper>
    </MUITesseraWrapper>
  );
};

export default ManagerLayout;
