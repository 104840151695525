import { Box, Divider, Grid, Link, Stack } from "@mui/joy";
import StyledText from "../text/styled_text";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { is } from "date-fns/locale";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import ConsentPreferences from "../legal/consent_banner";
import { ROUTES } from "../../routes/def";
import { ConstructNormalRoute } from "../../utils/subdomain/subdomain_utils";
import { adjustTransparency } from "../../utils/manager/color";
import usePalette, { useIsDarkTheme } from "../../theme/use_palette";
import { LEGAL_LINKS } from "../legal/legal_links";
import { text } from "stream/consumers";
import TesseraRedLogo from "../../assets/logos/tessera-logo-red-white-clipped.png";

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const navitgate = useNavigate();
  const palette = usePalette();
  const darkMode = useIsDarkTheme();

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  return (
    <Box
      sx={{
        backgroundColor: palette.charcoal,
        padding: "20px 0",
        width: "100%",
        zIndex: 5,
        position: "sticky",
        paddingBottom: isScreenSmall ? 10 : 0,
      }}
      id="footer"
    >
      <Grid
        container
        spacing={2}
        flexDirection={isScreenSmall ? "column" : "row"}
        justifyContent="center"
        alignItems="flex-start"
        mx={isScreenSmall ? "5%" : "20%"}
      >
        {/* Quick Links */}
        <Grid xs={isScreenSmall ? 12 : 3}>
          <StyledText
            color={palette.offWhite}
            level="body-sm"
            fontSize={20}
            fontWeight={700}
          >
            {t("footer.quick_links_title")}
          </StyledText>
          <Stack direction={"column"} spacing={0}>
            <Link href={ConstructNormalRoute(ROUTES.MAIN)}>Home</Link>
            {isLoggedIn ? (
              <Link href={ConstructNormalRoute(ROUTES.PROFILE)}>Profile</Link>
            ) : (
              <Link href={ConstructNormalRoute(ROUTES.LOGIN)}>Sign up</Link>
            )}
            <Link href={ConstructNormalRoute(ROUTES.BECOME_A_MANAGER)}>
              Become a manager
            </Link>
            {/* <Link href="/product">Product</Link> */}
            <Link href={ConstructNormalRoute(ROUTES.PRICING)}>Pricing</Link>
            <Link href={ROUTES.SUPPORT_CENTER} target="_blank">
              Support
            </Link>
            <Link href={ConstructNormalRoute(ROUTES.FAQ)}>FAQ</Link>
          </Stack>
        </Grid>

        <Grid xs={isScreenSmall ? 12 : 3}>
          <StyledText
            color={palette.offWhite}
            level="body-sm"
            fontSize={20}
            fontWeight={700}
          >
            {t("footer.legal_title")}
          </StyledText>
          <Stack direction={"column"} spacing={0}>
            <Link
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=25dd72fd-454b-4379-9f9d-df4c7b05c852"
              target="_blank"
            >
              Terms & Conditions
            </Link>
            <Link
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=8f234fc7-8c8a-46ba-9a63-0e35106f6ef3"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=c221b512-ea83-4e58-9262-76a3be28f742"
              target="_blank"
            >
              Return Policy
            </Link>
            <Link
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba68552e-041a-43f9-8bdc-6f68bdb74d7e"
              target="_blank"
            >
              Disclaimer
            </Link>
            <Link
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=0a27dbbc-88c9-4383-a965-f0c852ccc37e"
              target="_blank"
            >
              Acceptable Use Policy
            </Link>
            <Link
              href="https://app.termly.io/notify/8f234fc7-8c8a-46ba-9a63-0e35106f6ef3"
              target="_blank"
            >
              DSAR
            </Link>
            <Link href={LEGAL_LINKS.cookie_policy} target="_blank">
              Cookie Policy
            </Link>
            <ConsentPreferences />
          </Stack>
        </Grid>

        {/* Contact Information */}
        <Grid xs={isScreenSmall ? 12 : 3}>
          <StyledText
            color={palette.offWhite}
            level="body-sm"
            fontSize={20}
            fontWeight={700}
          >
            {t("footer.contact_title")}
          </StyledText>
          <StyledText color={palette.offWhite} level="body-sm" fontSize={18}>
            <Link href="mailto:info@tesseratickets.se">
              info@tesseratickets.se
            </Link>
          </StyledText>
        </Grid>

        {/* Social Media Links */}
        <Grid xs={isScreenSmall ? 12 : 3}>
          <StyledText
            color={palette.offWhite}
            level="body-sm"
            fontSize={20}
            fontWeight={700}
          >
            {t("footer.follow_us")}
          </StyledText>
          <StyledText color={palette.offWhite} level="body-sm" fontSize={18}>
            <Link
              href="https://www.linkedin.com/showcase/tesseratickets"
              target="_blank"
            >
              LinkedIn
            </Link>
          </StyledText>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        mt={4}
        onClick={() => navitgate(ROUTES.MAIN)}
      >
        <img
          src={TesseraRedLogo}
          alt="Tessera Logo"
          style={{
            width: "250px",
            cursor: "pointer", // Add cursor pointer
            filter: darkMode
              ? "brightness(0) saturate(100%) invert(1) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)"
              : "",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledText
          color={palette.offWhite}
          level="body-sm"
          fontSize={18}
          sx={{
            textAlign: "center",
          }}
        >
          © {new Date().getFullYear()} Dow Technology. All rights reserved.
        </StyledText>
      </Box>
    </Box>
  );
};

export default Footer;
