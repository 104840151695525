import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";

interface DrawerSectionsState {
  [sectionId: string]: boolean;
}

const initialState: DrawerSectionsState = {};

const drawerSectionsSlice = createSlice({
  name: "drawerSections",
  initialState,
  reducers: {
    toggleSection(state, action: PayloadAction<{ sectionId: string }>) {
      const { sectionId } = action.payload;
      state[sectionId] = !state[sectionId];
    },
    setSectionState(
      state,
      action: PayloadAction<{ sectionId: string; isOpen: boolean }>
    ) {
      const { sectionId, isOpen } = action.payload;
      state[sectionId] = isOpen;
    },
  },
  extraReducers: {
    // Handle the rehydration action
    [REHYDRATE]: (state, action) => {
      if (action.payload) {
        return {
          ...action.payload.drawerSections,
        };
      }
      return state;
    },
  },
});

export const { toggleSection, setSectionState } = drawerSectionsSlice.actions;
export default drawerSectionsSlice.reducer;
