import { motion } from "framer-motion";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import StyledText from "../text/styled_text";
import usePalette from "../../theme/use_palette";
import CloseIcon from "@mui/icons-material/Close";

const MotionModalDialog = motion(ModalDialog);

interface AnimationOptions {
  initial?: Record<string, any>;
  animate?: Record<string, any>;
  exit?: Record<string, any>;
  transition?: Record<string, any>;
}

const defaultAnimationOptions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, scale: 0.5 },
  transition: { duration: 1 },
};

interface InformationModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  width?: string;
  animationOptions?: AnimationOptions;
  preventCloseReasons?: string[];
  zIndex?: number;
}

const InformationModal: React.FC<InformationModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  width = "30%",
  animationOptions = {},
  preventCloseReasons = [],
  zIndex = 1300,
}) => {
  const mergedAnimationOptions = {
    ...defaultAnimationOptions,
    ...animationOptions,
  };

  const palette = usePalette();

  return (
    <Modal
      open={isOpen}
      onClose={(_, reason: string) => {
        if (!preventCloseReasons.includes(reason)) {
          onClose();
        }
      }}
      disableEscapeKeyDown={true}
      sx={{
        zIndex: zIndex,
      }}
    >
      <MotionModalDialog
        color="primary"
        size="sm"
        variant="outlined"
        sx={{
          width: width,
          bgcolor: palette.white,
        }}
        initial={mergedAnimationOptions.initial}
        animate={mergedAnimationOptions.animate}
        exit={mergedAnimationOptions.exit}
        transition={mergedAnimationOptions.transition}
      >
        {!preventCloseReasons.includes("backdropClick") && (
          <ModalClose onClick={() => onClose()} />
        )}
        {preventCloseReasons.includes("backdropClick") && (
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogTitle>
          <StyledText
            level="h3"
            color={palette.primaryDark}
            fontSize={28}
            fontWeight={700}
          >
            {title}
          </StyledText>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </MotionModalDialog>
    </Modal>
  );
};

export default InformationModal;
