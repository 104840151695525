import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  Option,
  Select,
  SelectOption,
  Stack,
  Textarea,
} from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "js-cookie";

import Title from "../text/title";
import usePalette from "../../theme/use_palette";
import { AppDispatch, RootState } from "../../store";
import {
  fetchUserFoodPreferencesStart,
  updateUserFoodPreferencesStart,
} from "../../redux/features/userFoodPreferences";
import { StyledFormLabelWithHelperText } from "../forms/form_labels";
import LoadingOverlay from "../Loading";
import StyledButton from "../buttons/styled_button";
import InformationModal from "../modal/information";
import FoodPreferencesAgreement from "./food_preferences_agreement";
import { LEGAL_LINKS } from "../legal/legal_links";
import StyledText from "../text/styled_text";
import { set } from "lodash";

interface FoodPreferencesProps {
  onSave?: () => void;
}

interface IFoodPreference {
  id: number;
  label: string;
  checked: boolean;
}

const FoodPreferences: React.FC<FoodPreferencesProps> = ({ onSave }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const palette = usePalette();

  const {
    userFoodPreferences,
    additionalNotes,
    loading: loadingFoodPref,
    gdpr_agreed: initialGdprAgree,
    needs_to_renew_gdpr: initialNeedsToRenewGdpr,
    save_success,
  } = useSelector((state: RootState) => state.foodPreferences);

  const { guestCustomer, loading } = useSelector(
    (state: RootState) => state.guestCustomer
  );

  const [selectedPreferences, setSelectedPreferences] = useState<string[]>([]);
  const [additionalNotesState, setAdditionalNotes] = useState<string>("");
  const [gdprAgree, setGdprAgree] = useState<boolean>(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    const shouldShowEditForm =
      Cookies.get("showFoodPreferencesEditForm") !== "false";

    setIsEditing(shouldShowEditForm);
  }, []);

  useEffect(() => {
    if (!loading) {
      dispatch(fetchUserFoodPreferencesStart({ guestCustomer }));
    }
  }, [dispatch, guestCustomer, loading]);

  useEffect(() => {
    if (userFoodPreferences) {
      const checkedPreferences = userFoodPreferences
        .filter((pref) => pref.checked)
        .map((pref) => String(pref.id)); // Ensure IDs are strings
      setSelectedPreferences(checkedPreferences);
      setGdprAgree(initialNeedsToRenewGdpr ? false : initialGdprAgree);
      setAdditionalNotes(additionalNotes || "");
      if (initialNeedsToRenewGdpr) {
        setIsEditing(true);
      }
    }
  }, [
    userFoodPreferences,
    additionalNotes,
    initialGdprAgree,
    initialNeedsToRenewGdpr,
  ]);

  useEffect(() => {
    if (save_success) {
      setIsEditing(false);
      Cookies.set("showFoodPreferencesEditForm", "false", { expires: 30 }); // Set cookie to hide edit form for 7 days
      if (onSave) onSave();
    }
  }, [save_success, onSave]);

  const handlePreferencesChange = (event: any, newValue: string[] | null) => {
    setSelectedPreferences(newValue ?? []);
  };

  const handleSave = () => {
    if (gdprAgree) {
      dispatch(
        updateUserFoodPreferencesStart({
          foodPreferences: selectedPreferences,
          additionalNotes: additionalNotesState,
          gdpr_agreed: gdprAgree,
          needs_to_renew_gdpr: initialNeedsToRenewGdpr,
          guestCustomer,
        })
      );
    }
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      {loadingFoodPref && <LoadingOverlay />}
      <AnimatePresence mode="wait">
        {isEditing ? (
          <motion.div
            key="form"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <StyledText
              color={palette.primaryMain}
              level="body-md"
              fontWeight={700}
              fontSize={22}
            >
              {t("profile.food_preferences.title")}
            </StyledText>
            <Box>
              <FormControl>
                <FormLabel>
                  <StyledText
                    level="body-sm"
                    fontSize={18}
                    fontWeight={700}
                    color={palette.charcoal}
                  >
                    {t(
                      "profile.food_preferences.allergies_and_dietary_restrictions_select"
                    )}
                  </StyledText>
                </FormLabel>
                <Select
                  multiple
                  value={selectedPreferences}
                  placeholder={t("profile.food_preferences.select_placeholder")}
                  onChange={handlePreferencesChange}
                  sx={{ minWidth: "13rem", borderColor: palette.primaryMain }}
                  renderValue={(selected: SelectOption<string>[]) => (
                    <Box sx={{ display: "flex", gap: "0.25rem" }}>
                      {selected.map((id) => {
                        const option = userFoodPreferences?.find(
                          (pref) => String(pref.id) === id.value
                        );
                        return option ? (
                          <Chip key={id.value} variant="soft" color="primary">
                            {option.label}
                          </Chip>
                        ) : null;
                      })}
                    </Box>
                  )}
                  slotProps={{
                    listbox: {
                      sx: { width: "100%" },
                    },
                  }}
                >
                  {userFoodPreferences?.map((option) => (
                    <Option key={option.id} value={String(option.id)}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                <StyledFormLabelWithHelperText>
                  {t(
                    "profile.food_preferences.allergies_and_dietary_restrictions_helperText"
                  )}
                </StyledFormLabelWithHelperText>
              </FormControl>

              <FormControl sx={{ mt: 2 }}>
                <FormLabel>
                  <StyledText
                    level="body-sm"
                    fontSize={18}
                    fontWeight={700}
                    color={palette.charcoal}
                  >
                    {t("profile.food_preferences.additional_notes")}
                  </StyledText>
                </FormLabel>
                <Textarea
                  minRows={2}
                  value={additionalNotesState}
                  onChange={(e) => setAdditionalNotes(e.target.value)}
                />
                <StyledFormLabelWithHelperText>
                  {t("profile.food_preferences.additional_notes_helperText")}
                </StyledFormLabelWithHelperText>
              </FormControl>

              <Box mt={2}>
                <FormControl>
                  <Checkbox
                    checked={gdprAgree}
                    onChange={(e) => setGdprAgree(e.target.checked)}
                  />
                  <StyledFormLabelWithHelperText>
                    <Trans i18nKey="profile.food_preferences.gdpr_agree_helperText">
                      I agree to the processing of my personal data for the
                      purpose of managing my food preferences
                      <Link href={LEGAL_LINKS.privacy_policy} target="_blank">
                        Food Preferences Privacy Policy
                      </Link>
                    </Trans>
                  </StyledFormLabelWithHelperText>
                </FormControl>
              </Box>

              <InformationModal
                isOpen={showPrivacyPolicy}
                onClose={() => setShowPrivacyPolicy(false)}
                title={t("profile.food_preferences.privacy_policy_title")}
                width="75%"
              >
                <FoodPreferencesAgreement />
              </InformationModal>

              <Box mt={2}>
                <StyledButton
                  size="md"
                  onClick={handleSave}
                  bgColor={palette.successLight}
                  color={palette.alwaysBlack}
                  disabled={!gdprAgree}
                >
                  {initialNeedsToRenewGdpr
                    ? t("form.button_update_gdpr")
                    : t("form.button_save")}
                </StyledButton>
              </Box>
            </Box>
          </motion.div>
        ) : (
          <motion.div
            key="summary"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <StyledText
                color={palette.primaryMain}
                level="body-md"
                fontWeight={700}
                fontSize={22}
              >
                {t("profile.food_preferences.title")}
              </StyledText>
              <IconButton
                onClick={() => {
                  setIsEditing(true);
                  Cookies.set("showFoodPreferencesEditForm", "true", {
                    expires: 30,
                  });
                }}
              >
                <EditIcon
                  sx={{
                    fontSize: 24,
                  }}
                />
              </IconButton>
            </Stack>
            <StyledText
              level="body-sm"
              fontSize={18}
              fontWeight={500}
              color={palette.successDark}
              sx={{
                mt: 1,
              }}
            >
              {t("profile.food_preferences.saved")}
            </StyledText>
            <Box>
              <StyledText
                level="body-sm"
                fontSize={22}
                fontWeight={600}
                color={palette.charcoal}
                sx={{
                  mt: 1,
                }}
              >
                {t(
                  "profile.food_preferences.allergies_and_dietary_restrictions"
                )}
              </StyledText>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {selectedPreferences.length > 0 ? (
                  selectedPreferences.map((id) => {
                    const pref = userFoodPreferences?.find(
                      (pref) => String(pref.id) === id
                    );
                    return pref ? (
                      <Chip
                        key={id}
                        variant="soft"
                        color="primary"
                        sx={{
                          bgcolor: palette.white,
                        }}
                      >
                        <StyledText
                          level="body-sm"
                          fontSize={18}
                          color={palette.charcoal}
                          fontWeight={600}
                        >
                          {pref.label}
                        </StyledText>
                      </Chip>
                    ) : null;
                  })
                ) : (
                  <StyledText
                    level="body-sm"
                    fontSize={18}
                    color={palette.greyDark}
                  >
                    {t("profile.food_preferences.no_preferences")}
                  </StyledText>
                )}
              </Box>
            </Box>
            {additionalNotesState && (
              <Box>
                <StyledText
                  level="body-sm"
                  fontSize={18}
                  fontWeight={600}
                  color={palette.charcoal}
                >
                  {t("profile.food_preferences.additional_notes")} {": "}
                </StyledText>

                <StyledText
                  level="body-sm"
                  fontSize={18}
                  fontWeight={500}
                  color={palette.charcoal}
                >
                  {additionalNotesState}
                </StyledText>
              </Box>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default FoodPreferences;
