import React from "react";
import { Outlet, useParams } from "react-router-dom";
import MUITesseraWrapper from "../../components/wrappers/page_wrapper_mui";
import DrawerBoxWrapper from "../../components/wrappers/manager_wrapper";

const EventLayout: React.FC = () => {
  const { eventID } = useParams();

  return (
    <MUITesseraWrapper navOptions={{ isDashboard: true }}>
      <DrawerBoxWrapper eventID={eventID}>
        <Outlet /> {/* Renders child routes */}
      </DrawerBoxWrapper>
    </MUITesseraWrapper>
  );
};

export default EventLayout;
