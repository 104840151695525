// BottomCart.tsx
import React from "react";
import { Box, Button, Stack } from "@mui/joy";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store";
import { ITicketRelease } from "../../../../types";
import { useTranslation } from "react-i18next";
import usePalette from "../../../../theme/use_palette";
import { motion } from "framer-motion";
import { openCartModal } from "../../../../redux/features/ticketOrderSlice";
import StyledText from "../../../text/styled_text";
import { adjustTransparency } from "../../../../utils/manager/color";
import useShowMobileNav from "../../../../hooks/nav/show_mobile_nav_hook";
import StyledButton from "../../../buttons/styled_button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
interface BottomCartProps {
  ticketRelease: ITicketRelease;
}

const BottomCart: React.FC<BottomCartProps> = ({ ticketRelease }) => {
  const { items } = useSelector((state: RootState) => state.ticketOrder);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const palette = usePalette();

  const isMobile = useShowMobileNav();

  // Filter items for the current ticket release
  const cartItemsForRelease = items.filter(
    (item) => item.ticket.ticket_release_id === ticketRelease.id
  );

  // Calculate total amount including addons
  const totalAmount = cartItemsForRelease.reduce((total, item) => {
    const ticketPrice = item.ticket.price;
    const addonsPrice = item.addons.reduce((sum, addon) => {
      const addonInfo = ticketRelease.add_ons?.find((a) => a.id === addon.id);
      return sum + (addonInfo ? addonInfo.price * addon.quantity : 0);
    }, 0);
    return total + ticketPrice + addonsPrice;
  }, 0);

  const cartIsEmpty = cartItemsForRelease.length === 0;

  const handleButtonClick = () => {
    dispatch(openCartModal());
  };

  return (
    <motion.div
      initial={{ y: "100%", x: "-50%", opacity: 0 }}
      animate={{ y: cartIsEmpty ? "100%" : "0%", opacity: cartIsEmpty ? 0 : 1 }}
      transition={{ type: "spring", stiffness: 220, damping: 20 }}
      style={{
        position: "fixed",
        bottom: isMobile ? "64px" : "16px",
        left: "50%",
        transform: "translateX(-50%)",
        width: isMobile ? "calc(100% - 32px)" : "100%",
        maxWidth: "600px",
        backgroundColor: adjustTransparency(palette.black, 0.85),
        padding: "16px",
        borderRadius: "16px",
        boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
        zIndex: 1500,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <StyledText
            level="body-md"
            color={palette.white}
            fontWeight={600}
            fontSize={22}
            startDecorator={
              <ShoppingCartIcon
                sx={{
                  color: palette.primaryMain,
                  fontSize: 30,
                }}
              />
            }
          >
            {t("event.ticket_release.checkout.total")}:
          </StyledText>
          <StyledText
            level="body-md"
            color={palette.white}
            fontWeight={700}
            fontSize={22}
          >
            {totalAmount.toFixed(2)} kr
          </StyledText>
        </Stack>{" "}
        <StyledButton
          size="md"
          onClick={handleButtonClick}
          disabled={cartIsEmpty}
          bgColor={palette.primaryMain}
          color={palette.alwaysWhite}
        >
          {t("event.ticket_release.checkout.view_cart")}
        </StyledButton>
      </Box>
    </motion.div>
  );
};

export default BottomCart;
