import React, { useEffect, useState } from "react";
import {
  Drawer,
  SwipeableDrawer, // Import SwipeableDrawer
  List,
  useTheme,
  Box,
  Divider,
  IconButton,
  Tooltip,
  useMediaQuery,
  LinearProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import EventIcon from "@mui/icons-material/Event";
import PushPinIcon from "@mui/icons-material/PushPin";
import MenuIcon from "@mui/icons-material/Menu"; // For mobile drawer button
import StyledText from "../../../text/styled_text";
import DrawerListItem from "../drawer_list_item";
import CollapsibleDrawerSection from "../collapsible_drawer_section";
import { AppDispatch, RootState } from "../../../../store";
import {
  darkerColor,
  isColorDark,
  lightenColor,
} from "../../../../utils/manager/color";
import {
  selectAccentColor,
  selectMainColor,
  selectTextColor,
} from "../../../../redux/features/managerThemeSlice";
import { DRAWER_WIDTH } from "../../../../hooks/drawer_pinned_hook";
import { ROUTES, generateRoute } from "../../../../routes/def";
import { toggleSection } from "../../../../redux/features/drawer/drawerSectionsSlice";
import { useDispatch } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import useDrawerHeight from "../../../../hooks/drawer_height_hook";
import usePalette, { useIsDarkTheme } from "../../../../theme/use_palette";
import StorageIcon from "@mui/icons-material/Storage";
import BusinessIcon from "@mui/icons-material/Business";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
interface ManagerDashboardDrawerComponentProps {
  handlePinned: (isPinned: boolean) => void;
}

const ManagerDashboardDrawerComponent: React.FC<
  ManagerDashboardDrawerComponentProps
> = ({ handlePinned }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

  // Redux Selectors
  const { isPinned: initialIsPinned } = useSelector(
    (state: RootState) => state.drawerPinned
  );
  const { network, loading } = useSelector((state: RootState) => state.network);
  const mainColor = useSelector(selectMainColor);
  const accentColor = useSelector(selectAccentColor);
  const sectionsState = useSelector((state: RootState) => state.drawerSections);

  // Local State
  const [isPinned, setIsPinned] = useState<boolean | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false); // State for mobile drawer

  const location = useLocation();
  const darkMode = useIsDarkTheme();
  const palette = usePalette();

  // Handle Pinned State on Mount
  useEffect(() => {
    if (isPinned === null && initialIsPinned !== null) {
      setIsPinned(initialIsPinned);
    }
  }, [initialIsPinned, isPinned]);

  const drawerHeight = useDrawerHeight();

  // Conditional rendering if pinned state is not yet determined
  if (isPinned === null) return null;

  // Derived state for determining drawer width and content visibility
  const isExtended = isScreenSmall ? mobileDrawerOpen : isHovered || isPinned;
  const drawerWidth = isExtended ? DRAWER_WIDTH : theme.spacing(7);
  const backgroundColor = isExtended ? palette.spaceCadetExtraDark : mainColor;
  const textColorBasedOnBg = isColorDark(backgroundColor) ? "white" : "black";

  const handlePinToggle = () => {
    const newPinnedState = !isPinned;
    setIsPinned(newPinnedState);
    handlePinned(newPinnedState);
  };

  // Drawer content as a reusable function
  const drawerContent = (
    <Box sx={{ position: "relative", height: "100%" }}>
      <Box
        sx={{
          padding: theme.spacing(2),
          backgroundColor: backgroundColor,
          height: "80px",
        }}
      >
        {isExtended && (
          <>
            <StyledText
              level="h3"
              color={textColorBasedOnBg}
              fontSize={20}
              fontWeight={600}
              sx={{
                width: "75%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              forceTextPointer
              onClick={() => {
                navigate(ROUTES.MANAGER_DASHBOARD);
              }}
            >
              {network?.name || "Network Name"}
            </StyledText>
            <StyledText
              level="h3"
              color={textColorBasedOnBg}
              fontSize={14}
              fontWeight={400}
              sx={{
                width: "75%",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {t("manager.drawer.title")}
            </StyledText>
          </>
        )}
      </Box>
      <List sx={{ pb: "128px" }}>
        <DrawerListItem
          text={t("manager.drawer.events")}
          icon={<EventIcon />}
          navigateTo={ROUTES.MANAGER_DASHBOARD}
          isSelected={location.pathname === ROUTES.MANAGER_DASHBOARD} // Pass isSelected prop
        />
        <Divider sx={{ my: 0.25 }} />
        <DrawerListItem
          text={t("manager.drawer.teams")}
          icon={<GroupsIcon />}
          navigateTo={ROUTES.MANAGER_TEAMS}
          isSelected={location.pathname === ROUTES.MANAGER_TEAMS} // Pass isSelected prop
        />
        <Divider sx={{ my: 0.25 }} />
        <CollapsibleDrawerSection
          planEnrollment={network?.plan_enrollment}
          title={t("manager.drawer.settings.title")}
          icon={<SettingsIcon />}
          drawerExtended={isExtended}
          isOpen={sectionsState["manager_settings"] || false}
          toggleSection={() =>
            dispatch(toggleSection({ sectionId: "manager_settings" }))
          }
          subItems={[
            {
              title: t("manager.drawer.settings.general"),
              clickable: true,
              navigateTo: ROUTES.MANAGER_SETTINGS_GENERAL,
            },
            {
              title: t("manager.drawer.settings.users"),
              clickable: true,
              navigateTo: ROUTES.MANAGER_SETTINGS_USERS,
            },
            {
              title: t("manager.drawer.settings.subscription"),
              clickable: true,
              navigateTo: ROUTES.MANAGER_SETTINGS_SUBSCRIPTION,
            },
            {
              title: t("manager.drawer.settings.customize"),
              clickable: true,
              navigateTo: ROUTES.MANAGER_SETTINGS_CUSTOMIZATION,
            },
            {
              title: t("manager.drawer.settings.social_media"),
              clickable: true,
              navigateTo: ROUTES.MANAGER_SETTINGS_SOCIAL_MEDIA,
            },
            {
              title: t("manager.drawer.settings.api"),
              clickable: true,
              navigateTo: ROUTES.MANAGER_SETTINGS_API_KEYS,
            },
          ]}
        />
        <Divider sx={{ my: 0.25 }} />
        <DrawerListItem
          text={t("manager.drawer.contact_database")}
          icon={<StorageIcon />}
          navigateTo={ROUTES.MANAGER_CONTACT_DATABASE}
          isSelected={location.pathname === ROUTES.MANAGER_CONTACT_DATABASE} // Pass isSelected prop
        />
        <Divider sx={{ my: 0.25 }} />
        <DrawerListItem
          text={t("manager.drawer.merchant_portal")}
          icon={<DashboardIcon />}
          navigateTo={"https://surfboardmerchant.com/login"}
          isSelected={false} // Pass isSelected prop
          customBackgroundColor={
            darkMode
              ? darkerColor(palette.primaryExtraDark, 0.5)
              : lightenColor(palette.primaryExtraDark, 0.5)
          }
        />
        <Divider sx={{ my: 0.25 }} />
        <DrawerListItem
          text={t("manager.drawer.business_page")}
          icon={<BusinessIcon />}
          navigateTo={generateRoute(ROUTES.NETWORK_BUSINESS_PAGE, {
            networkSlug: network?.details?.network_slug,
          })}
          isSelected={false} // Pass isSelected prop
          customBackgroundColor={
            darkMode
              ? darkerColor(palette.primaryExtraDark, 0.5)
              : lightenColor(palette.primaryExtraDark, 0.5)
          }
          openInNewTab={true}
        />
        <Divider sx={{ my: 0.25 }} />
        <DrawerListItem
          text={t("manager.drawer.tessera_ai")}
          icon={<AutoAwesomeIcon />}
          navigateTo={ROUTES.TESSERA_AI}
          isSelected={location.pathname === ROUTES.TESSERA_AI} // Pass isSelected prop
        />
        <Divider sx={{ my: 0.25 }} />
        <DrawerListItem
          icon={<AddIcon />}
          text={t("manager.drawer.create_event")}
          navigateTo={generateRoute(ROUTES.CREATE_EVENT, {})}
          isSelected={
            location.pathname === generateRoute(ROUTES.CREATE_EVENT, {})
          } // Pass isSelected prop
        />
      </List>
      {/* Pin Icon Button for desktop */}
      {!isScreenSmall && isExtended && (
        <Box
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
        >
          <IconButton onClick={handlePinToggle}>
            <Tooltip
              title={
                isPinned ? t("manager.drawer.unpin") : t("manager.drawer.pin")
              }
            >
              <PushPinIcon
                sx={{
                  color: isPinned
                    ? lightenColor(palette.successLight, 0.2)
                    : palette.greyLight,
                }}
                style={{ transform: "rotate(45deg)" }}
              />
            </Tooltip>
          </IconButton>
        </Box>
      )}
    </Box>
  );

  return isScreenSmall ? (
    <>
      {/* Mobile View: SwipeableDrawer */}
      <SwipeableDrawer
        open={mobileDrawerOpen}
        onClose={() => setMobileDrawerOpen(false)}
        onOpen={() => setMobileDrawerOpen(true)}
        swipeAreaWidth={30}
        ModalProps={{
          keepMounted: true, // Better performance for mobile
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: DRAWER_WIDTH,
            height: drawerHeight, // Dynamically set height
            backgroundColor: mainColor,
            overflowX: "hidden",
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "darkgrey",
              borderRadius: "4px",
            },
          },
        }}
      >
        {drawerContent}
      </SwipeableDrawer>
      {/* Mobile Drawer Open Button */}
      <IconButton
        onClick={() => setMobileDrawerOpen(true)}
        sx={{
          position: "fixed",
          bottom: 64,
          left: 16,
          zIndex: 1000,
          backgroundColor: mainColor,
          color: textColorBasedOnBg,
          "&:hover": {
            backgroundColor: backgroundColor,
          },
        }}
      >
        <MenuIcon />
      </IconButton>
    </>
  ) : (
    // Desktop View: Permanent Drawer
    <>
      <Drawer
        open={isExtended}
        variant="permanent"
        onMouseEnter={() => !isPinned && setIsHovered(true)}
        onMouseLeave={() => !isPinned && setIsHovered(false)}
        sx={{
          position: "relative",
          flexShrink: 0,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            height: drawerHeight, // Dynamically set height
            backgroundColor: mainColor,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            overflowX: "hidden",
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "darkgrey",
              borderRadius: "4px",
            },
          },
        }}
      >
        {drawerContent}
      </Drawer>
      {loading && (
        <LinearProgress
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 9999,
          }}
        />
      )}
    </>
  );
};

export default ManagerDashboardDrawerComponent;
