import { Form, Formik } from "formik";
import {
  ICustomerLoginValues,
  ICustomerSignupValues,
  IGuestCustomer,
  IGuestCustomerForm,
  ITicketRelease,
} from "../../../../types";
import * as Yup from "yup";
import { Box, Divider, FormControl, Link, Stack } from "@mui/joy";
import { FormCheckbox, FormInput } from "../../../forms/input_types";
import {
  StyledFormLabel,
  StyledFormLabelWithHelperText,
} from "../../../forms/form_labels";
import { StyledErrorMessage } from "../../../forms/messages";
import StyledButton from "../../../buttons/styled_button";
import usePalette from "../../../../theme/use_palette";
import StyledText from "../../../text/styled_text";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../../../store";
import { useDispatch } from "react-redux";
import {
  customerLoginRequest,
  customerSignupRequest,
} from "../../../../redux/features/authSlice";
import { useSelector } from "react-redux";
import {
  hasLottery,
  ticketReleaseRequiresAccount,
} from "../../../../utils/manage_event/can_edit_payment_deadline";
import { useTranslation } from "react-i18next";
import CustomerLoginForm from "../../../customer/login_form";
import { createSignupValidationSchema } from "../../../../validation/user/customer_signup_validation";
import CustomerSignupForm from "../../../customer/signup_form";
import OAuthGoogle from "../../../../pages/user_login/oauth/google_login";

interface MakeTicketRequestUserDetailsProps {
  accountIsRequired: boolean;
  ticketRelease: ITicketRelease;
  onSignupContinue: (values: ICustomerSignupValues) => void;
  onLoginContinue: () => void;
  forceShowLogin?: boolean;
  forceEmail?: string;
}

const MakeTicketRequestUserDetails: React.FC<
  MakeTicketRequestUserDetailsProps
> = ({
  accountIsRequired,
  ticketRelease,
  onLoginContinue,
  onSignupContinue,
  forceShowLogin = false,
  forceEmail = "",
}) => {
  const dispatch: AppDispatch = useDispatch();
  const palette = usePalette();

  const [hasAccount, setHasAccount] = useState<boolean>(forceShowLogin);

  const onSignup = (values: ICustomerSignupValues) => {
    dispatch(customerSignupRequest(values));

    if (values.is_saved) {
      setHasAccount(true);
    }

    onSignupContinue(values);
  };

  const onLogin = (values: ICustomerLoginValues) => {
    dispatch(customerLoginRequest(values));
  };

  // "event.ticket_release.request_process"
  const { t } = useTranslation();

  return (
    <Box>
      {/* Already has an account */}
      {accountIsRequired && (
        <StyledText
          level="body"
          color={palette.primaryMain}
          fontWeight={500}
          fontSize={18}
        >
          {t(
            "event.ticket_release.request_process.account_required_description"
          )}
        </StyledText>
      )}
      {hasAccount ? (
        <>
          <CustomerLoginForm onLogin={onLogin} forceEmail={forceEmail} />

          <StyledText
            level="body"
            color={palette.black}
            fontSize={16}
            sx={{
              my: 0.5,
            }}
          >
            {t("common.or")}
          </StyledText>

          <StyledText
            level="body"
            color={palette.charcoalTransparent}
            fontSize={16}
            fontWeight={500}
            onClick={() => setHasAccount(false)}
          >
            <Link href="#">
              {t("event.ticket_release.request_process.continue_as_guest")}
            </Link>
          </StyledText>
          <Box
            sx={{
              my: 1,
            }}
          >
            <OAuthGoogle />
          </Box>
        </>
      ) : (
        <CustomerSignupForm
          onSignup={onSignup}
          accountIsRequired={accountIsRequired}
        />
      )}
      {!hasAccount && (
        <Box
          sx={{
            pb: 2,
          }}
        >
          <Stack mt={2} direction="row" alignItems="center" spacing={2}>
            <StyledText
              level="body-sm"
              color={palette.primaryDark}
              fontWeight={400}
              fontSize={16}
            >
              {t(
                "event.ticket_release.request_process.already_have_an_account"
              )}{" "}
              <Link href="#" onClick={() => setHasAccount(true)}>
                {t("form.button_sign_in")}
              </Link>
            </StyledText>
          </Stack>
          <StyledText
            level="body-sm"
            color={palette.charcoal}
            fontWeight={400}
            fontSize={16}
          >
            {t("customer.oauth")}
          </StyledText>
          <OAuthGoogle />
        </Box>
      )}
    </Box>
  );
};

export default MakeTicketRequestUserDetails;
