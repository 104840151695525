import { parseISO } from "date-fns";
import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import { toast } from "react-toastify";

interface ConvertibleKeys {
  created_at?: string;
  CreatedAt?: string;
  DeletedAt?: string | null;
  ID?: number;
  UpdatedAt?: string;
}

export interface ApiResponse<T> {
  status: string;
  data: T;
  message: string;
}

function convertKeysAndParseDates<T>(response: ApiResponse<T>): ApiResponse<T> {
  // Recursive function to handle objects and arrays
  function recursiveConvert(obj: any): void {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      // Check if the value is an object or an array and recursively call if necessary
      if (value && typeof value === "object") {
        if (Array.isArray(value)) {
          value.forEach((item) => recursiveConvert(item));
        } else {
          recursiveConvert(value);
        }
      }
    });

    const dateKeys = [
      "created_at",
      "updated_at",
      "deleted_at",
      "open",
      "close",
      "date",
      "end_date",
      "payment_deadline",
      "payed_at",
      "deactivated_at",
      "deletion_scheduled_at",
    ];

    // Parse dates in the current object level
    Object.keys(obj).forEach((key) => {
      if (dateKeys.includes(key) && obj[key] != null) {
        // Only parse if it's a date field and not null
        obj[key] = parseISO(obj[key]);
      }
    });
  }

  // Start the recursive conversion from the response.data
  try {
    if (!response.data) {
      return response;
    }

    recursiveConvert(response.data);
  } catch (error: any) {
    console.error("An error occurred while parsing the response:", error);
    toast.error(
      "An error occurred while parsing the response, contact support"
    );
  }

  return response;
}

export { convertKeysAndParseDates };

export function fetchApi<T>(
  endpoint: string,
  withCredentials: boolean = true,
  fullUrl: boolean = false
): Promise<ApiResponse<T>> {
  // Note the change here to return ApiResponse<T>
  const url = fullUrl
    ? endpoint
    : `${process.env.REACT_APP_BACKEND_URL}${endpoint}`;

  return axios
    .get(url, {
      withCredentials,
    })
    .then((response: AxiosResponse<ApiResponse<T>>) => response.data) // Accessing the data property directly
    .then(convertKeysAndParseDates); // Assuming this function can handle ApiResponse<T>
}

export function postApi<T>(
  endpoint: string,
  data: any,
  withCredentials: boolean = true,
  fullUrl: boolean = false,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void // Add optional onUploadProgress parameter
): Promise<ApiResponse<T>> {
  let url = endpoint;
  if (!fullUrl) {
    url = `${process.env.REACT_APP_BACKEND_URL}${endpoint}`;
  }

  const config = {
    withCredentials,
    headers:
      data instanceof FormData ? { "Content-Type": "multipart/form-data" } : {},
    onUploadProgress, // Include the onUploadProgress callback if provided
  };

  return axios
    .post<ApiResponse<T>>(url, data, {
      withCredentials,
    })
    .then((response) => response.data) // Directly returning ApiResponse<T>
    .then(convertKeysAndParseDates);
}

export function putApi<T>(
  url: string,
  data: any,
  withCredentials: boolean = true,
  fullUrl: boolean = false
): Promise<ApiResponse<T>> {
  const useUrl = fullUrl ? url : `${process.env.REACT_APP_BACKEND_URL}${url}`;

  return axios
    .put<ApiResponse<T>>(useUrl, data, {
      withCredentials,
    })
    .then((response) => response.data) // Directly returning ApiResponse<T>
    .then(convertKeysAndParseDates);
}

export function deleteApi<T>(
  url: string,
  data: any,
  withCredentials: boolean = true,
  fullUrl: boolean = false
): Promise<ApiResponse<T>> {
  const useUrl = fullUrl ? url : `${process.env.REACT_APP_BACKEND_URL}${url}`;
  return axios
    .delete<ApiResponse<T>>(useUrl, {
      data,
      withCredentials,
    })
    .then((response) => response.data) // Directly returning ApiResponse<T>
    .then(convertKeysAndParseDates);
}

export function uploadFileApi(
  url: string,
  file: File,
  progressCallback: (progress: number) => void
): Promise<string> {
  const formData = new FormData();
  formData.append("file", file);

  // Use the modified postApi function
  return postApi<{ file_url: string }>(
    url,
    formData,
    true,
    true,
    (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        progressCallback(percentCompleted);
      }
    }
  )
    .then((response) => {
      // Assuming the URL is in the response data, adjust accordingly
      return response.data.file_url;
    })
    .catch((error) => {
      throw error; // This ensures errors are caught in the saga
    });
}
